
.revenue {
    @apply text-black;

    td {
        @apply bg-firebrick-light;
    }
}

.expenses {
    @apply text-black;

    td {
        @apply bg-steelblue-light;
    }
}

.assets {
    @apply text-black;
    td {
        @apply bg-teal-2-light;
    }
}

.liabilities {
    @apply text-black;

    td {
        @apply bg-goldenrod-light;
    }
}