.profit-loss-table, .balance-sheet-table {
  @apply block overflow-y-auto #{!important};

  & td {
    padding: .15rem .3rem .15rem .3rem !important;
  }
}

.balance-sheet-table {
  max-height: 146px;
}

.practice-tool {
  .nav-tabs {
    .nav-link {
      &.active, &:hover {
        border-color: transparent transparent transparent transparent;
      }
      &.active {
        border-bottom-color: #003d51;
        border-bottom-width: 2px;
      }
    }
    border-top: 1px solid #dee2e6;
  }
}

.ledger-table {
  td {
    padding-top: .1rem !important;
    padding-bottom: .1rem !important;
  }
  .btn-link {
    line-height: 1;
  }
}

.btn-invert-disabled {
  opacity: .65;

  &:disabled {
    opacity: 1 !important;
  }
}