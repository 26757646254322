$warning: #f0ad4e;
$success: #28a745;
$danger: #dc3545;

$secondary: #9b1d44;
$primary: #003d51;

$theme-colors: (
  "primary": $primary,
        "secondary": $secondary,
        "lightseagreen": #18ad90,
        "maroon": #6d0a1f,
        "dodgerblue": #2979ff,
        "snow": #fafafa,
        "darkslategray": #003d51,
        "steelblue": #2c85bf,
        "firebrick": #c3402c,
        "teal-2": #038667,
        "goldenrod": #d9b334,
        "lavender": #e0e8ea,
        "chocolate": #cc6633,
        "gainsboro": #e0e0e0,
        "orange-light": #f6993f,
        "red": #e3342f

);

.btn-orange-light {
  color: white !important;
}


$sidebar-selected: #e0e8ea;

:export {
  warning: $warning;
  success: $success;
  danger:  $danger;
  primary:        $primary;
  secondary:      $secondary;
  lightseagreen:  map-get($theme-colors, lightseagreen);
  maroon:         map-get($theme-colors, maroon);
  dodgerblue:     map-get($theme-colors, dodgerblue);
  snow:           map-get($theme-colors, snow);
  darkslategray:  map-get($theme-colors, darkslategray);
}