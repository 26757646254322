@tailwind utilities;
@import "./colors";

@page { size: auto }

@mixin validation($color) {
  .form-control, .Select-control, .rc-time-picker-input {
    border-color: $color;
  }
  .form-control-label, .form-check-label, .form-check-input {
    color: $color;
  }
}

.has-warning {
  @include validation($warning);
}

.has-success {
  @include validation($success);
}

.has-danger {
  @include validation($danger)
}

.has-warning .form-control-label {
  color: #292b2c;
}

$sidebar-width: 16rem;
$header-height: 5.5rem;
$footer-height: 3rem;

.header {
  @apply flex fixed pin-x pin-t bg-primary z-10;
  height: $header-height;

  &__inner {
    @apply bg-white mb-4 pl-10 pr-16 flex flex-1 items-center;
  }

  &__branding {
    @apply flex items-center font-medium;

    img {
      @apply mr-5;
    }
  }

  &__welcome {
    @apply uppercase font-bold mr-8 tracking-wide;
  }

  &__logout a {
    @apply uppercase font-bold opacity-50 text-black no-underline tracking-wide;
  }
}

.sidebar {
  @apply fixed pin-y pin-l bg-primary;
  width: $sidebar-width;
  padding-top: $header-height;

  ul {
    @apply list-reset;

    li {
      @apply py-6 pl-10;

      a {
        @apply text-white no-underline tracking-wide;
      }

      &.selected {
        background-color: $sidebar-selected;

        a {
          @apply text-primary font-bold;
        }
      }
    }
  }
}

.content {
  @apply pt-3;
  margin-left: $sidebar-width;
  margin-top: $header-height;
  height: calc(100vh - 9rem);

  &--no-sidebar {
    @apply ml-0;
  }
}

.footer {
  @apply flex justify-end pr-4;
  margin-top: -$footer-height;
}

.subheading {
  @apply block mb-2 font-bold text-lg;
}

.link {
  @apply text-secondary font-medium;
}

.account {
  &__contact_information {
    @apply border-0 border-r border-grey border-solid;
  }
}

.draft {
  &__editor {
    height: 275px !important;

    .public-DraftStyleDefault-block {
      margin: 0em 0;
      padding: 0.5em 0;
    }
  }
}

@screen print {
  body {
    transform: scale(0.70);
  }

  .header {
    @apply hidden;
  }

  .content {
    @apply mt-0 pt-0;
  }
}

@responsive {
  .bg-invisible {
    @apply bg-transparent text-grey-darkest #{!important}
  }
}